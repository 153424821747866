import { useState } from "react";
import { useDispatch } from "react-redux";
import logo from "../assets/logo.svg";
import Button from "../components/Button";
import CustomInput from "../components/Input";
import { ENVIRONMENT } from "../components/constants/constant";
import { loginUser } from "../redux/slices/userSlices";
import { AppDispatch } from "../redux/store";
import { showToast } from "../utils/showToast";

function getRandomIntInclusive(min: number, max: number) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
}

// @ts-ignore
const isLocal = ENVIRONMENT === "local";

function Area({ areaId, onChange }: { areaId: number; onChange: (checked: boolean) => void; }) {
  const [checked, setChecked] = useState(isLocal);
  const inputId = `check_${areaId}`;
  let areaText = "";
  switch (areaId) {
    case 1:
      areaText = "Portrait Studio";
      break;
    case 2:
      areaText = "Galaxy Wraps";
      break;
    case 3:
      areaText = "Check-in Facebook";
      break;
  }
  return <div className="flex gap-2 mt-4">
    <div className="inline-flex items-center">
      <label
        className="relative flex items-center pr-3 rounded-full cursor-pointer"
        htmlFor={inputId}
      >
        <input
          type="checkbox"
          className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#29834a] checked:bg-[#4ade80] checked:before:bg-[#4ade80] hover:before:opacity-10"
          id={inputId}
          checked={checked}
          onChange={(e) => {
            setChecked(!checked);
            onChange(!checked);
          }}
        />
        <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-1/4 -translate-y-2/4 -translate-x-1/4 peer-checked:opacity-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5"
            viewBox="0 0 20 20"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </span>
      </label>
      <label
        className="mt-px font-normal text-[11px] text-gray-700 cursor-pointer select-none"
        htmlFor={inputId}>
        {areaText}
      </label>
    </div>
  </div>;
}

function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: isLocal ? "test" : "",
    phone: isLocal
      ? "085" + getRandomIntInclusive(0, 1234567).toString().padStart(7, "0")
      : "",
    email: isLocal ? "test@localhost.com" : "",
    checkbox1: isLocal,
    checkbox2: isLocal,
    area1: isLocal,
    area2: isLocal,
    area3: isLocal,
  });

  const checkArea = () => {
    return formData.area1 || formData.area2 || formData.area3;
  };

  const handleLogin = () => {
    if (
      formData.name === "" ||
      formData.phone === "" ||
      formData.email === "" ||
      (!checkArea())
    ) {
      showToast({ type: "error", message: "Vui lòng nhập đầy đủ thông tin" });
      return;
    }
    setIsLoading(true);
    dispatch(loginUser(formData))
      .unwrap()
      .catch((error) => {
        setIsLoading(false);
        showToast({ type: "error", message: error });
      });
  };

  return (
    <div className="flex flex-col justify-center px-5 py-20 mx-auto w-full text-base font-bold max-w-[480px] text-neutral-400">
      <img loading="lazy" src={logo} className="w-full aspect-[5]" alt="logo" />
      <div className="flex flex-col px-6 py-8 mt-8 w-full bg-indigo-50 rounded-2xl border border-solid border-stone-300">
        <div className="text-neutral-800 font-medium">Họ và tên</div>
        <CustomInput
          type="text"
          placeholder="Tên"
          value={formData.name}
          onChange={(e) =>
            setFormData({
              ...formData,
              name: e.target.value,
            })
          }
        />
        <div className="mt-4 text-neutral-800 font-medium">Số Điện Thoại</div>
        <CustomInput
          type="text"
          placeholder="Số Điện thoại"
          value={formData.phone}
          onChange={(e) =>
            setFormData({
              ...formData,
              phone: e.target.value,
            })
          }
        />
        <div className="mt-1 text-xs">
          * Mỗi số điện thoại chỉ được sử dụng 1 lần
        </div>
        <div className="mt-4 text-neutral-800 font-medium">Email</div>
        <CustomInput
          type="text"
          placeholder="Email"
          value={formData.email}
          onChange={(e) =>
            setFormData({
              ...formData,
              email: e.target.value,
            })
          }
        />
        <div className="mt-4 text-neutral-800 font-medium">
          Tôi đã tham gia trải nghiệm
        </div>
        <Area areaId={1} onChange={(checked: boolean) => {
          setFormData({
            ...formData,
            area1: checked,
          });
        }} />
        <Area areaId={2} onChange={(checked: boolean) => {
          setFormData({
            ...formData,
            area2: checked,
          });
        }} />
        <Area areaId={3} onChange={(checked: boolean) => {
          setFormData({
            ...formData,
            area3: checked,
          });
        }} />
        <div className="flex gap-2 mt-4">
          <div className="inline-flex items-center">
            <label
              className="relative flex items-center pr-3 rounded-full cursor-pointer"
              htmlFor="check_condition_1"
            >
              <input
                type="checkbox"
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#29834a] checked:bg-[#4ade80] checked:before:bg-[#4ade80] hover:before:opacity-10"
                id="check_condition_1"
                checked={formData.checkbox1}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox1: !formData.checkbox1,
                  })
                }
              />
              <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-1/4 -translate-y-2/4 -translate-x-1/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
            <label
              className="mt-px font-normal text-[11px] text-gray-700 cursor-pointer select-none"
              htmlFor="check_condition_1"
            >
              Tôi chấp nhận{" "}
              <a
                className="text-[#4671b5]"
                href="https://www.samsung.com/vn/info/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                chính sách quyền riêng tư
              </a>{" "}
              của Samsung.
            </label>
          </div>
        </div>
        <div className="flex gap-2 mt-4">
          <div className="inline-flex items-center">
            <label
              className="relative flex items-center pr-3 rounded-full cursor-pointer"
              htmlFor="check_condition_2"
            >
              <input
                type="checkbox"
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#29834a] checked:bg-[#4ade80] checked:before:bg-[#4ade80] hover:before:opacity-10"
                id="check_condition_2"
                checked={formData.checkbox2}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    checkbox2: !formData.checkbox2,
                  })
                }
              />
              <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-1/4 -translate-y-2/4 -translate-x-1/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
            <label
              className="mt-px font-normal text-[11px] text-gray-700 cursor-pointer select-none"
              htmlFor="check_condition_2"
            >
              Tôi đồng ý nhận thông báo về các chương trình giảm giá và khuyến
              mãi từ Samsung và đối tác.
            </label>
          </div>
        </div>
        {formData.checkbox1 &&
          formData.checkbox2 &&
          formData.name !== "" &&
          formData.phone !== "" &&
          formData.email !== "" &&
          checkArea() ? (
          <Button
            isLoading={isLoading}
            label="Đăng ký"
            handleClick={handleLogin}
          />
        ) : (
          <div className="font-bold text-center items-center px-4 py-2 mt-4 text-cexnter text-white bg-[#80828D] rounded-[10000px] hover:bg-slate-300 cursor-pointer">
            Đăng ký
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
